import { useFrame, useLoader } from '@react-three/fiber'
import { MeshTransmissionMaterial } from '@react-three/drei'
import { GLTFLoader } from 'three-stdlib'
import { useRef } from 'react'
import { forwardRef } from 'react'

export const Prism = forwardRef(({ onRayOver, onRayOut, onRayMove, ...props }, fRef) => {
  const { nodes } = useLoader(GLTFLoader, '/gltf/prism.glb')

  // useFrame((state) => {
  //   ref.current.scale.set(state.viewport.width / 4, state.viewport.height / 4, 1)
  // })

  return (
    <group ref={fRef} {...props}>
      <mesh position={[1.5, 0, -1.6]} renderOrder={10} scale={5} dispose={null} geometry={nodes.Cone.geometry}>
        <MeshTransmissionMaterial clearcoat={1} transmission={0.95} metalness={0.5} thickness={0.6} roughness={0.1} anisotropy={0.2} chromaticAberration={1} toneMapped={false} />
      </mesh>
    </group>
  )
})
