import React, { useRef, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

export default function Spark({
  particlesCount = 150,
  position = [0, 0, 0],
  size = 2,
  area = {
    x: 5.9,
    y: 5.9,
    z: 5.9
  },
  opacity = 0.1
}) {
  const particlesRef = useRef();

  const particlesGeometry = useMemo(() => {
    const positions = new Float32Array(particlesCount * 3);

    for (let i = 0; i < particlesCount; i++) {
      positions[i * 3 + 0] = (Math.random() - 0.5) * area.x;
      positions[i * 3 + 1] = (Math.random() - 0.5) * area.y;
      positions[i * 3 + 2] = (Math.random() - 0.5) * area.z;
      2;
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
    return geometry;
  }, [particlesCount, area]);

  const particlesMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        size: { value: size },
        scale: { value: 5 },
        color: { value: new THREE.Color("white") },
        opacity: { value: opacity }
      },
      vertexShader: THREE.ShaderLib.points.vertexShader,
      fragmentShader: `
      uniform vec3 color;
      uniform float opacity;
      void main() {
          vec2 xy = gl_PointCoord.xy - vec2(0.5);
          float ll = length(xy);
          gl_FragColor = vec4(color, step(ll, 0.5) * opacity);
        }
      `,
      defines: {
        USE_SIZEATTENUATION: true
      },
      alphaTest: 0.1,
      transparent: true
    });
  }, [size, opacity]);

  useFrame((state, delta) => {
    if (particlesRef.current) {
      particlesRef.current.rotation.x = -state.clock.elapsedTime * 0.07;
      particlesRef.current.position.y =
        Math.sin(state.clock.elapsedTime) * 0.04;
      particlesRef.current.rotation.z =
        Math.sin(state.clock.elapsedTime) * 0.09;
      particlesRef.current.position.x =
        Math.cos(state.clock.elapsedTime) * 0.06;
    }
  });

  return (
    <group position={position}>
      <points
        ref={particlesRef}
        renderOrder={1}
        geometry={particlesGeometry}
        material={particlesMaterial}
      />
    </group>
  );
}
