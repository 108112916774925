import { Sparkles } from '@react-three/drei'
import { useRef, useEffect } from 'react'
import { Rainbow } from './Rainbow'
import { Flare } from './Flare'
import { Prism } from './Prism'
import { lerp, lerpV3 } from '../util'
import { useFrame } from '@react-three/fiber'

export default function Scene() {
  const flare = useRef(null)
  const spot = useRef(null)
  const rainbow = useRef(null)
  const rainbow2 = useRef(null)

  const prismRef = useRef(null)

  useFrame((state) => {
    lerpV3(flare.current.position, [state.pointer.x, 0.5, 0], 0.012)
    lerp(flare.current.rotation, 'z', -Math.atan(state.pointer.x, state.pointer.y / 0.5), 0.002)
    lerpV3(rainbow.current.position, [state.pointer.x, 0.5, 0], 0.012)
    lerp(rainbow.current.rotation, 'z', state.pointer.x / 2 + Math.atan(0.8), 0.012)
    lerp(prismRef.current.rotation, 'z', -1.5 + -state.pointer.x / 6, 0.01)
    lerp(prismRef.current.rotation, 'x', -0.5 + -state.pointer.y / 6, 0.01)
  })

  return (
    <>
      {/* Lights */}
      <pointLight position={[-2, 9, -4]} intensity={1.05} />
      <pointLight ref={spot} position={[-3, -3, 9]} intensity={2} />
      <Prism ref={prismRef} scale={1.4} position={[-0.7, 1.5, -3]} rotation={[-0.2, 0.6, -0.25]} />
      {/* Rainbow and flares */}
      <Rainbow ref={rainbow} startRadius={0.02} endRadius={0.9} fade={0.01} transparentProp={true} depthProp={false} />
      <Rainbow ref={rainbow2} startRadius={0.3} scale={7} endRadius={3.5} fade={0.1} position={[-1, 0, -8.5]} rotation={[0, 0, -2.25]} />
      <Flare ref={flare} visible={true} renderOrder={10} scale={2.25} streak={[6.5, 10, 0]} />
    </>
  )
}
