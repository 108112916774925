import { useLoader } from '@react-three/fiber'
import { Bloom, EffectComposer, HueSaturation, LUT, Vignette } from '@react-three/postprocessing'
import { LUTCubeLoader } from 'postprocessing'

export default function LightsAndEffects() {
  const lutTexture = useLoader(LUTCubeLoader, 'lut.cube')

  return (
    <>
      <EffectComposer multisampling={0} disableNormalPass>
        <Bloom mipmapBlur radius={0.9} intensity={0.2} luminanceThreshold={0.2} levels={9} />
        <Bloom mipmapBlur levels={9} intensity={1.5} luminanceThreshold={1} luminanceSmoothing={0.1} />
        <HueSaturation saturation={-0.5} />
        <Vignette darkness={0.7} />
        <LUT lut={lutTexture} tetrahedralInterpolation />
      </EffectComposer>
    </>
  )

}
