import { forwardRef, useRef } from 'react'
import { extend, useFrame, useThree } from '@react-three/fiber'
import { shaderMaterial, useTexture } from '@react-three/drei'

const RainbowMaterial = shaderMaterial(
  {
    time: 0,
    speed: 1,
    fade: 0.5,
    startRadius: 1,
    endRadius: 0,
    emissiveIntensity: 2.5,
    ratio: 1,
    noiseTexture: null,
  },
  /*glsl*/ ` varying vec2 vUv;
    varying vec3 vWorldPosition;
    void main() {
      vUv = uv;
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
      gl_Position = projectionMatrix * viewMatrix * modelPosition;
    }`,
  /*glsl*/ ` varying vec2 vUv;
    varying vec3 vWorldPosition;
    uniform float fade;
    uniform float speed;
    uniform float startRadius;
    uniform float endRadius;
    uniform float emissiveIntensity;
    uniform float time;
    uniform float ratio;
    uniform sampler2D noiseTexture;
  
    vec2 mp;
    vec3 physhue2rgb(float hue, float ratio) {
      return smoothstep(vec3(0.0),vec3(1.0), abs(mod(hue + vec3(0.0,1.0,2.0)*ratio,1.0)*2.0-1.0));
    }
          
    vec3 custom_iri (float angle, float thickness) {      
      float NxV = cos(angle);     
      float lum = 0.164;      
      float luma = 0.04070;      
      vec3 tint = vec3(0.49639,0.78252,0.8723);      
      float interf0 = 2.4;      
      float phase0 = 1.0 / 2.8;      
      float interf1 = interf0 * 4.0 / 3.0;      
      float phase1 = phase0;      
      float f = (1.0 - NxV) * (1.0 - NxV);
      float interf = mix(interf0, interf1, f);
      float phase = mix(phase0, phase1, f);
      float dp = (NxV - 1.0) * 0.5;            
      vec3 hue = mix(physhue2rgb(thickness * interf0 + dp, thickness * phase0), physhue2rgb(thickness * interf1 + 0.1 + dp, thickness * phase1), f);      
      vec3 film = hue * lum + vec3(0.9639,0.78252,0.18723) * luma;      
      return vec3((film * 3.0 + pow(f,12.0))) * tint;
    }

    float _saturate (float x) {
      return min(1.0, max(0.0,x));
    }

    vec3 _saturate (vec3 x) {
      return min(vec3(1.,1.,1.), max(vec3(0.,0.,0.),x));
    }
    
    vec3 bump3y(vec3 x, vec3 yoffset) {
      vec3 y = vec3(1.,1.,1.) - x * x;
      y = _saturate(y-yoffset);
      return y;
    }

    vec3 star_burst(float w, float t) {    
      float x = _saturate((w - 400.0)/ 300.0);
      const vec3 c1 = vec3(3.54585104, 2.93225262, 2.41593945);
      const vec3 x1 = vec3(0.69549072, 0.49228336, 0.27699880);
      const vec3 y1 = vec3(0.02312639, 0.15225084, 0.52607955);
      const vec3 c2 = vec3(3.90307140, 3.21182957, 3.96587128);
      const vec3 x2 = vec3(0.11748627, 0.86755042, 0.66077860);
      const vec3 y2 = vec3(0.84897130, 0.88445281, 0.73949448);
      return bump3y(c1 * (x - x1), y1) + bump3y(c2 * (x - x2), y2);
    }

    mat2 rotate2D(float angle) {
      float s = sin(angle);
      float c = cos(angle);
      return mat2(c, -s, s, c);
    }

    void main() {
      const vec2 vstart = vec2(0.5, 0.5);
      const vec2 vend = vec2(1.0, 0.5);
      vec2 dir = vstart - vend;
      float len = length(dir);
      float cosR = dir.y / len;
      float sinR = dir.x / len;
      vec2 uv = (mat2(cosR, -sinR, sinR, cosR) * (vUv * vec2(ratio, 1.) - vec2(0., 1.) - vstart * vec2(1., -1.)) / len);
      float a = atan(uv.x, uv.y) * 10.0;
      float s = uv.y * (endRadius - startRadius) + startRadius;
      float w = (uv.x / s + .5) * 300. + 400. + a;
      vec3 c = star_burst(w, time); // [400, 700]
      float l = 1. - smoothstep(fade, 1., uv.y);
      float area = uv.y < 0. ? 0. : 3.5;

      vec2 godRay = vUv.xy - vec2(0.5, .5);
      vec2 uvDirection = vec2(atan(godRay.y, godRay.x), atan(godRay.y, godRay.x * .4));

      vec4 sampleNoise = texture2D(noiseTexture, vec2(uv.x, mod( uvDirection.y * uvDirection.x - time * 0.04, 1.0)));
      float brightness = smoothstep(0., 0.5, c.x + c.y + c.z);
      vec3 co = c / custom_iri(sampleNoise.x * 0.5 * 3.14159, 1.0 - uv.y + time * 0.2 - sampleNoise.y * 0.2) / 20.0;  
      co += sampleNoise.xyz;    
      gl_FragColor = vec4(area * co * sampleNoise.xyz * l * brightness * emissiveIntensity, 0.55);
      if (gl_FragColor.a < 0.1) discard;
      #include <encodings_fragment>
    }`
)

extend({ RainbowMaterial })


export const Rainbow = forwardRef(({ startRadius = 0, endRadius = 0.5, emissiveIntensity = 2.5, fade = 0.25, depthProp, transparentProp, ...props }, fRef) => {
  const material = useRef(null)
  const { width, height } = useThree((state) => state.viewport)
  const noiseTexture = useTexture('/textures/noise.jpeg')
  const length = Math.hypot(width, height) + 1.5
  useFrame((state, delta) => (material.current.time += delta * material.current.speed))
  return (
    <mesh ref={fRef} scale={[length, length, 1]} {...props}>
      <planeGeometry />
      <rainbowMaterial noiseTexture={noiseTexture} ref={material} emissiveIntensity={4.5} key={RainbowMaterial.key} fade={fade} startRadius={startRadius} endRadius={endRadius} ratio={1} toneMapped={false} depthTest={depthProp} transparent={transparentProp} />
    </mesh>
  )
})
