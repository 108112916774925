import { Canvas } from '@react-three/fiber'
import LightsAndEffects from './components/LightsAndEffects'
import Spark from './components/Spark'
import Scene from './components/Scene'

export function App() {

  return (
    <Canvas dpr={1} orthographic camera={{ position: [0, 0, 5], zoom: 250 }} gl={{ antialias: false }}>
      <color attach="background" args={['#000000']} />
      <LightsAndEffects />
      <Scene />
      <Spark />
    </Canvas>
  )
}
