import './styles.css';
import { useState } from 'react';

const TYPEFORM_LINK = "https://ed7hj152vhk.typeform.com/to/mIN1NICn";
const CONTACT_URL = 
  process.env.REACT_APP_PUBLIC_ENV === "production" 
    ? "https://backend.blockadelabs.com/api/v1/next/create-contact" 
    : "https://backend-staging.blockadelabs.com/api/v1/next/create-contact";
const CONTACT_KEY = 
  process.env.REACT_APP_PUBLIC_ENV === "production" 
    ? "JI6Z3YsVMph7VVHnCB4ZrOfgRxSEJ67UdufPbt2Gi4A2ie2pDxn3uxvqELP6"
    : "pVYMjI7PZwmJY5doy6row4kmUste9Yn0XcxDdANwxHBG3tQdyKqm6FoJUP0D";

function validateEmail(email) {
    var regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
}

export default function Overlay() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.length === 0 || !validateEmail(email)) {
      alert("Please provide a valid email address.");
      return;
    }

    setIsSubmitting(true);
    fetch(
      `${CONTACT_URL}?api_key=${CONTACT_KEY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      }
    )
    .then((response) => {
      if (response.status !== 200) {
        alert("Something went wrong. Please try again later.");
        console.error(error);
      } else {
        const link = document.createElement('a');
        link.href = `${TYPEFORM_LINK}#email=${email}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch((error) => {
      alert("Something went wrong. Please try again later.");
      console.error(error);
    })
    .finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <>
      {/* Header */}
      <div className="brand">
        <a
          href="https://www.blockadelabs.com/"
          target="_blank"
          rel="noreferrer">
          <img src="/Blockade-Labs-Brand.svg" width="180px" alt="Blockade Labs" />
        </a>
      </div>

      <div className="container">
        {/* Heading */}
        <div className='wrapper'>
          <h1>
            We're about to change the way you generate images.
          </h1>
          <h3>Sign up to be among the first to know about it.</h3>

          {/* Form */}
          <form onSubmit={handleSubmit} >
            <div>
              <input 
                className='input-email' 
                required 
                type="email" 
                placeholder="EMAIL" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                type="submit"
                className="button-waitlist"
                disabled={isSubmitting}
              >
                JOIN THE WAITLIST
              </button>
            </div>
            <div className='consent'>
              By joining the waitlist, you agree to receive updates, promotional content, early access information, as well as future marketing materials from Blockade Labs.
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
